/* DatePickerStyles.css */

.custom-date-picker .rmdp-container {
  width: 100% !important;
  padding: 10px !important;
  border: 1px solid #ced4da !important;
  border-radius: 4px !important;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075) !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
}

.custom-date-picker .rmdp-container:focus {
  border-color: #80bdff !important;
  outline: 0 !important;
  box-shadow: 0 0 5px rgba(128, 189, 255, 0.5) !important;
}

.custom-date-picker .rmdp-panel {
  background-color: #ffffff !important;
  border: 1px solid #ced4da !important;
  border-radius: 4px !important;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1) !important;
}

.custom-date-picker .rmdp-panel .rmdp-day,
.custom-date-picker .rmdp-panel .rmdp-header-values {
  font-size: 14px !important;
  color: #333 !important;
}

.custom-date-picker .rmdp-panel .rmdp-day.rmdp-selected {
  background-color: #007bff !important;
  color: #fff !important;
}

.custom-date-picker .rmdp-panel .rmdp-day:hover {
  background-color: #f8f9fa !important;
  color: #333 !important;
}


.rmdp-input {
  border: 1px solid #ebedf2;
  border-radius: 5px;
  margin: 1px 0;  
  font-size: 14px;
  padding: .6rem 1rem;
  height: inherit !important;
  width: 100%;
}
.rmdp-container {display:block}



.myContainer {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  flex: 1 1 0px;
}

.myDatePicker {   
  width: 100%;  
}
