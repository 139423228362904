/* .modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
} */

.modal-dialog {
  background: white;
  padding: 20px;
  border-radius: 5px;
  width: 600px;  /* Increased width to 600px */
}

.button-group .btn {
  margin-right: 8px; /* Adjust the space between buttons */
}
