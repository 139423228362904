/* .modal-responsive .modal-dialog {
  max-width: 95%;
  width: auto;
} */

.event-img {
  width: 100%;
  max-width: 350px; /* Prevents image from being too large */
  height: auto;
  border-radius: 10px;
  object-fit: cover;
}
.modal-responsive .modal-dialog {
    max-width: 500px; /* Adjust width */
    width: 90%;
    margin: auto; /* Center it */
    display: flex;
    align-items: center; /* Center vertically */
    min-height: auto; /* Remove extra height */
  }
  
  .modal-responsive .modal-content {
    max-height: 70vh; /* Reduce modal height */
    overflow: hidden;
    border-radius: 10px;
  }
  
  .modal-responsive .modal-body {
    max-height: 50vh; /* Set max height for body */
    overflow-y: auto; /* Enable scrolling if needed */
    padding: 10px;
  }
  
  .modal-responsive .modal-header {
    padding: 10px 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .modal-responsive .modal-footer {
    padding: 10px 15px;
  }
  
  .modal-responsive .event-img {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
  }
  
  .modal.fade.show {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  }
  