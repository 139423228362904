.layout-wrapper {
  display: flex;
  transition: margin-left 0.3s ease;
}

.sidebar {
  width: 250px; /* Width of the sidebar when expanded */
  height: 89vh !important; /* Full viewport height */
  overflow-y: auto; /* Enable vertical scrolling */
  transition: width 0.3s ease;
}

.sidebar.collapsed {
  width: 80px; /* Width of the sidebar when collapsed */
}

.main-content {
  display: flex;
  flex-grow: 1; /* Allow content to take available space */
}

.content-area {
  flex-grow: 1;
  transition: margin-left 0.3s ease; /* Smooth transition for content */
}

.content-area.expanded {
  margin-left: 80px; /* Adjust based on the collapsed sidebar width */
}

.nav-item i {
  font-size: 20px; /* Adjust icon size */
}

.nav-item p {
  display: inline; /* Show titles when expanded */
}

.sidebar.collapsed .nav-item p {
  display: none; /* Hide titles when collapsed */
}

/* Scrollbar styles */
.sidebar::-webkit-scrollbar {
  width: 8px; /* Width of the scrollbar */
}

.sidebar::-webkit-scrollbar-thumb {
  background-color: #888; /* Color of the scrollbar thumb */
  border-radius: 10px; /* Round scrollbar thumb */
}

.sidebar::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Darker color on hover */
}
.sidebar {
  width: 250px; /* Width of the sidebar when expanded */
  height: 100vh; /* Full viewport height */
  overflow-y: auto; /* Enable vertical scrolling */
  transition: width 0.3s ease;
}

.sidebar.collapsed {
  width: 80px; /* Width of the sidebar when collapsed */
}

.toggle-sidebar {
  background: black; /* Set background color to black */
  color: #fff; /* Text color white for contrast */
  border: none;
  font-size: 24px; /* Adjust as needed */
  cursor: pointer;
  padding: 10px;
  position: absolute; /* Positioning relative to sidebar */
  top: 20px; /* Adjust position */
  right: -30px; /* Move it outside the sidebar */
  transition: right 0.3s ease; /* Smooth transition for toggle button */
}

.sidebar.collapsed .toggle-sidebar {
  right: -60px; /* Adjust for collapsed state */
}

.container-fluid.collapsed .vertical-collapsed .main-panel {
    margin-left: 50px;
    width: calc(100% - 50px);
}
@media screen and (min-width: 991px) {
  /* .sidebar.collapsed:hover {
      width: 250px;
  } */
  .sidebar .nav > .nav-item a p, .sidebar[data-background-color="white"] .nav > .nav-item a p {
    font-size: 14px;
    margin-bottom: 0px;
    margin-right: 5px;
    white-space: nowrap;
    color: #8d9498;
}
  .sidebar.collapsed .nav-item a .badge, 
  .sidebar.collapsed .nav-item a span, 
  .sidebar.collapsed .nav-item a .caret, 
  .sidebar.collapsed .nav-item a p
  {
    display: none;
    transition: all .3s;
  }
  /* .sidebar.collapsed:hover .nav-item a .badge, 
  .sidebar.collapsed:hover .nav-item a span, 
  .sidebar.collapsed:hover .nav-item a .caret, 
  .sidebar.collapsed:hover .nav-item a p
  {
    display: block;    
  } */
}
@media screen and (max-width: 991px) {
    .sidebar {
        position: fixed;
        left: -250px !important;  /* Changed from transform */
        top: 0;
        height: 100vh;
        width: 250px !important;
        z-index: 1000;
        background-color: white;
        transition: left 0.3s ease-in-out;  /* Changed from transform */
        box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
    }

    .nav_open .sidebar {
        left: 0 !important;  /* Changed from transform */
    }

    .main-panel {
        margin-left: 0 !important;
        width: 100% !important;
    }

    /* Optional: Add overlay when sidebar is open */
    .nav_open::before {
        content: '';
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
        z-index: 999;
    }
}

/* Remove or comment out the conflicting media query */
/* @media (max-width: 767px) {
    .sidebar {
        ...
    }
} */

/* Add this to your existing CSS */
.navbar-toggler i {
    color: white;
}

.btn-toggle i {
    color: white;
}