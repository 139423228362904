.container {
	max-width: 80%; /* Adjust the width as needed */
	margin: 0 auto; /* Center the container */
}

.d-flex {
	display: flex;
	flex-wrap: wrap;
}

.company-info,
.invoice-to-info {
	flex: 1; /* Allows sections to grow and fill the container equally */
	margin-right: 20px; /* Space between the two sections */
}

.company-info {
	border-right: 1px solid #ddd; /* Optional: Add a border between sections */
	padding-right: 20px; /* Optional: Add padding to the right side */
}

.invoice-to-info {
	flex: 1; /* Ensure this section also takes up space */
}

h2 {
	font-weight: bold; /* Makes the text bolder */
	color: #333; /* Darker color for the text */
	font-size: 24px; /* Adjust the size as needed */
	margin-bottom: 10px; /* Space below the heading */
}
