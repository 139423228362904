/* Ensure the sidebar is collapsed properly */
.vertical-collapsed .sidebar {
	width: 80px; /* Adjust based on your design */
	position: fixed; /* Keep it fixed on the left */
	height: 100vh; /* Full height */
}

/* Expand content area when sidebar is collapsed */
.vertical-collapsed .main-panel {
	margin-left: 50px; /* Adjust based on the collapsed sidebar width */
	width: calc(100% - 50px); /* Ensure full width minus the sidebar */
}

/* When sidebar is expanded */
.sidebar-enable .main-panel {
	margin-left: 250px; /* Adjust based on your sidebar width */
	width: calc(100% - 250px); /* Ensure full width minus the sidebar */
}

/* Ensure wrapper has full width */
.wrapper {
	display: flex;
	width: 100%; /* Full width */
}
