.map-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 1000px;
  max-width: 90vw;
  height: 80%; /* Increase the modal height */

  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  outline: none;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.map-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(190, 188, 188, 0.75);
}

.modal-contentimage {
  display: flex;
  flex-direction: column;
  align-items: center;
}




