.custom-modal {
    max-width: 50%; /* Adjust width as needed */
  
  }
  /* emailmodal.css */

.custom-modal .modal-content {
    border-radius: 15px;
    border: none;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  }
  
  .custom-modal .modal-header {
    background-color: #007bff;
    color: white;
   
  }
  
  .custom-modal .modal-title {
    font-size: 1.5rem;
  }
  
  .custom-modal .modal-body {
    padding: 2rem;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  
  .custom-modal .form-group {
    margin-bottom: 1.5rem;
  }
  
  .custom-modal .form-group label {
    font-weight: bold;
  }
  
  .custom-modal .form-control {
    border-radius: 15px;
    padding: 0.75rem;
    border: 1px solid #ced4da;
  }
  
  .custom-modal .form-control:focus {
    border-color: #80bdff;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }
  
  .custom-modal .modal-footer {
    padding: 1rem 2rem;
    border-top: none;
    justify-content: space-between;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
  }
  
  .custom-modal .btn-secondary {
    background-color: #6c757d;
    border: none;
    border-radius: 15px;
    padding: 0.5rem 1.5rem;
  }
  
  .custom-modal .btn-secondary:hover {
    background-color: #5a6268;
  }
  
  .custom-modal .btn-primary {
    background-color: #007bff;
    border: none;
    border-radius: 15px;
    padding: 0.5rem 1.5rem;
  }
  
  .custom-modal .btn-primary:hover {
    background-color: #0056b3;
  }
  
  .custom-modal .SunEditor {
    border-radius: 15px;
    border: 1px solid #ced4da;
    overflow: hidden;
  }
  
  .custom-modal .SunEditor .se-toolbar {
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
  }
  
  .custom-modal .SunEditor .se-container {
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
  }
  