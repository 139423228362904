.mains-panels {
  position: relative;
  width: calc(100% - 250px);
  height: 10vh;
  min-height: 100%;
  float: right;
  transition: all .3s; }
  .mains-panels > .content {
    padding: 0px !important;
    min-height: calc(100% - 123px);
    margin-top: 62px;
    overflow: hidden; }
  .mains-panels > .content-full {
    padding: 0px !important;
    min-height: calc(100% - 123px);
    margin-top: 63px;
    overflow: hidden; }
  .mains-panels .page-header {
    display: flex;
    align-items: center;
    margin-bottom: 20px; }
    .mains-panels .page-header .page-title {
      margin-bottom: 0px; }
    .mains-panels .page-header .btn-page-header-dropdown {
      width: 35px;
      height: 35px;
      font-size: 14px;
      padding: 0px;
      color: #6b6b6b;
      box-shadow: 0 2px 14px 0 rgba(144, 116, 212, 0.1) !important;
      border: 0; }
      .mains-panels .page-header .btn-page-header-dropdown:after {
        display: none; }
    .mains-panels .page-header .dropdown-menu {
      margin-top: 15px;
      top: 0px !important; }
      .mains-panels .page-header .dropdown-menu:after {
        width: 0;
        height: 0;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-bottom: 8px solid #ffffff;
        position: absolute;
        top: -8px;
        right: 32px;
        content: ''; }
  .mains-panels .page-divider {
    height: 0;
    margin: .3rem 0 1rem;
    overflow: hidden;
    border-top: 1px solid #ebecec; }