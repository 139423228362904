*{
    box-sizing:border-box;
   /* outline:1px solid ;*/
  }
  
  .wrapper-1{
    width:100%;
    height:100vh;
    display: flex;
  flex-direction: column;
  }
  .wrapper-2{
    padding :30px;
    text-align:center;
  }
  h1{
      font-family: 'Kaushan Script', cursive;
    font-size:4em;
    letter-spacing:3px;
    color:#5892FF ;
    margin:0;
    margin-bottom:20px;
  }
  .wrapper-2 p{
    margin:0;
    font-size:1.3em;
    color:#aaa;
    font-family: 'Source Sans Pro', sans-serif;
    letter-spacing:1px;
  }
  .go-home{
    color:#fff;
    background:#5892FF;
    border:none;
    padding:10px 50px;
    margin:30px 0;
    border-radius:30px;
    text-transform:capitalize;
    box-shadow: 0 10px 16px 1px rgba(174, 199, 251, 1);
  }


  
  @media (min-width:360px){
    h1{
      font-size:4.5em;
    }
    .go-home{
      margin-bottom:20px;
    }
  }
  
  @media (min-width:600px){
   
    .wrapper-1{
    height: initial;
    max-width:620px;
    margin:0 auto;
    margin-top:50px;
    box-shadow: 4px 8px 40px 8px rgba(88, 146, 255, 0.2);
  }
    
  }