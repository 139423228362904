/* Tutorial Container */

.tut{
    padding: 74px;
}
.tutorial-container {
    max-width: 800px;
    margin: 0 auto;
    margin-top: 40px;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(22, 19, 19, 0.76);
}

/* Tutorial Header */
.tutorial-header {
    text-align: center;
    margin-bottom: 20px;
}

.tutorial-title {
    font-size: 24px;
    margin-bottom: 10px;
    color: #0273f5;
}

.tutorial-step-title {
    font-size: 20px;
    margin-bottom: 10px;
    color: #555;
}

.tutorial-step-description {
    font-size: 16px;
    color: #777;
}

/* Tutorial Body */
.tutorial-body {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.tutorial-image-container {
    margin-bottom: 20px;
}

.tutorial-image {
    max-width: 100%;
    border-radius: 5px;
}

.tutorial-step-info {
    font-size: 14px;
    color: #999;
}

/* Tutorial Actions */
.tutorial-actions {
    display: flex;
    justify-content: center;
}

.tutorial-button {
    padding: 10px 20px;
    margin: 0 10px;
    font-size: 16px;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.tutorial-button:hover {
    background-color: #0056b3;
}
.skip-button {
    background-color: #6c757d;
}
.next-button
{ 
    
    background-color: #045543
}
.next-button:hover { 
    background-color: #0b997a;
}
.skip-button:hover {
    background-color: #495057;
}
