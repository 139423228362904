.vendor-details {
	display: flex;
	justify-content: space-between;
}

.vendor-section {
	width: 48%; /* Adjust width as needed */
	border: 1px solid #ccc;
	border-radius: 8px;
	padding: 16px;
	margin: 8px;
	background-color: #f9f9f9;
}

.image-gallery {
	display: flex;
	gap: 20px; /* Space between images */
}

.image-gallery img {
	width: 150px; /* Size of product images */
	height: auto; /* Maintain aspect ratio */
	border-radius: 4px;
}

.license-images div {
	margin-bottom: 10px;
}

.license-images img {
	width: 150px; /* Size of license images */
	height: auto; /* Maintain aspect ratio */
	border-radius: 4px;
}

.image-item {
	display: flex;
	flex-direction: column;
	align-items: center; /* Center the title and image */
}

.image-item h4 {
	margin-bottom: 8px; /* Space between title and image */
	font-size: 1rem; /* Adjust font size as needed */
}

.license-images {
	display: flex; /* Use flexbox */
	flex-wrap: wrap; /* Allow items to wrap to the next line if necessary */
	gap: 20px; /* Space between items */
}

.image-item {
	display: flex;
	flex-direction: column;
	align-items: center; /* Center the title and image */
	width: 150px; /* Set a fixed width for items */
}

.image-item img {
	width: 100%; /* Make the image fill the width of the item */
	height: auto; /* Maintain aspect ratio */
	border-radius: 4px;
}
