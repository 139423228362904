:root {
	--green_color: #0cbb2a;
	--red_color: rgb(67, 17, 250);
	--orange_color: rgb(255, 176, 7);
	--blue_color: #378be6;
	--peach_color: #d9a891;
	--rejected_color: #f00;
	--invoice_color: #f115cd;
	--present_color: #fc7a00;
}
.card {
	border-radius: 5px;
	border: solid 1px #e5e5e5;
}
.card .card-body {
	padding: 10px;
}

.month_scroll {
	max-height: calc(112vh - 373px);
	overflow-y: auto;
	overflow-x: hidden;
}
.custom-control.custom-radio,
.custom-control.custom-checkbox {
	margin-bottom: 10px;
	margin-right: 16px;
}
.custom-control-label::before,
.custom-control-label::after {
	width: 20px;
	height: 20px;
	border-radius: 2px;
}
.custom-control-label {
	position: relative;
	margin-bottom: 0;
	font-size: 16px !important;
	padding: 0 7px 0 3px;
	vertical-align: middle;
	line-height: 25px;
	cursor: pointer;
}

.green_text {
	color: var(--green_color);
}

.green_bg {
	background: var(--green_color);
}
.red_bg {
	background: var(--red_color);
}
.orange_bg {
	background: var(--orange_color);
}
.blue_bg {
	background: var(--blue_color);
}
.peach_bg {
	background: var(--peach_color);
}
.present_bg {
	background: var(--present_color);
}

.custom-checkbox.green .custom-control-input:checked ~ .custom-control-label::before {
	background: var(--green_color);
}
.custom-checkbox.red .custom-control-input:checked ~ .custom-control-label::before {
	background: var(--red_color);
}
.custom-checkbox.orange .custom-control-input:checked ~ .custom-control-label::before {
	background: var(--orange_color);
}
.custom-checkbox.blue .custom-control-input:checked ~ .custom-control-label::before {
	background: var(--blue_color);
}

/* Add underlines based on payment status */
.green-underline {
	text-decoration-color: var(--green_color) !important; /* Green underline */
	text-decoration-thickness: 4px !important; /* Set underline thickness */
	text-decoration-style: solid !important; /* Solid underline style */
	border-bottom: 4px solid var(--green_color) !important; /* Border bottom for green underline */
	padding-bottom: 2px !important; /* Add padding to avoid overlap */
}


.yellow-underline {
	text-decoration-color: var(--orange_color) !important; /* Green underline */
	text-decoration-thickness: 4px !important; /* Set underline thickness */
	text-decoration-style: solid !important; /* Solid underline style */
	border-bottom: 4px solid var(--orange_color) !important; /* Border bottom for green underline */
	padding-bottom: 2px !important; /* Add padding to avoid overlap */
}
.orange-underline {
	text-decoration-color: var(--orange_color) !important; /* Orange underline */
	text-decoration-thickness: 4px !important; /* Set underline thickness */
	text-decoration-style: solid !important; /* Solid underline style */
	border-bottom: 4px solid var(--orange_color) !important; /* Border bottom for orange underline */
	padding-bottom: 2px !important; /* Add padding to avoid overlap */
}

.blue-underline {
	text-decoration-color: var(--blue_color) !important; /* Blue underline */
	text-decoration-thickness: 4px !important; /* Set underline thickness */
	text-decoration-style: solid !important; /* Solid underline style */
	border-bottom: 4px solid var(--blue_color) !important; /* Border bottom for blue underline */
	padding-bottom: 2px !important; /* Add padding to avoid overlap */
}

.peach-underline {
	text-decoration-color: var(--peach_color) !important; /* Blue underline */
	text-decoration-thickness: 4px !important; /* Set underline thickness */
	text-decoration-style: solid !important; /* Solid underline style */
	border-bottom: 4px solid var(--peach_color) !important; /* Border bottom for blue underline */
	padding-bottom: 2px !important; /* Add padding to avoid overlap */
}

.red-underline {
	text-decoration-color: var(--red_color) !important; /* Blue underline */
	text-decoration-thickness: 4px !important; /* Set underline thickness */
	text-decoration-style: solid !important; /* Solid underline style */
	border-bottom: 4px solid var(--red_color) !important; /* Border bottom for blue underline */
	padding-bottom: 2px !important; /* Add padding to avoid overlap */
}

.unchecked {
	text-decoration: none !important; /* No underline for unchecked items */
}

.rejected-bg {
	background-color: var(--rejected_color);
	color: white;
}

.rejected-underline {
	text-decoration-color: var(--rejected_color) !important; /* Blue underline */
	text-decoration-thickness: 4px !important; /* Set underline thickness */
	text-decoration-style: solid !important; /* Solid underline style */
	border-bottom: 4px solid var(--rejected_color) !important; /* Border bottom for blue underline */
	padding-bottom: 2px !important; /* Add padding to avoid overlap */
}

.invoice-underline {
	text-decoration-color: var(--invoice_color) !important; /* Blue underline */
	text-decoration-thickness: 4px !important; /* Set underline thickness */
	text-decoration-style: solid !important; /* Solid underline style */
	border-bottom: 4px solid var(--invoice_color) !important; /* Border bottom for blue underline */
	padding-bottom: 2px !important; /* Add padding to avoid overlap */
}

.invoice-bg {
	background-color: var(--invoice_color);
	color: white;
}

.present-underline {
	text-decoration-color: var(--present_color) !important; /* Blue underline */
	text-decoration-thickness: 4px !important; /* Set underline thickness */
	text-decoration-style: solid !important; /* Solid underline style */
	border-bottom: 4px solid var(--present_color) !important; /* Border bottom for blue underline */
	padding-bottom: 2px !important; /* Add padding to avoid overlap */
}

.status-container {
	display: flex;
	flex-wrap: wrap;
	gap: 10px; /* Adjust the gap between the items */
	margin: 0;
  }
  
  .status-container span {
	padding: 10px 20px;
	border-radius: 5px;
	font-size: 14px; /* Adjust font size if needed */
  }